import { Injectable } from '@angular/core';
import { Bitstream } from 'src/app/core/shared/bitstream.model';
import { DSONameService } from 'src/app/core/breadcrumbs/dso-name.service';
import { FileUtil } from './fileutil';
import { Fancybox } from '@fancyapps/ui';
import { hasValue } from '../empty.util';

@Injectable({
  providedIn: 'root'
})
export class GalleryService {
  private items: object[] = [];

  private prepended = 0;
  private appended = 0;

  constructor( private dsoNameService: DSONameService ) { }

  public getIndexFromPrepended( prependedIdx: number ): number {
    return prependedIdx;
  }

  public getIndexFromAppended( appendedIdx: number ): number {
    return this.prepended + appendedIdx;
  }

  public getIndexForBundle( bundle: string, idx: number ): number {
    if ( bundle === 'ORIGINAL' ) {
      return this.getIndexFromPrepended( idx );
    } else if ( bundle === 'PRESERVATION' ) {
      return this.getIndexFromAppended( idx );
    } else {
      return idx;
    }
  }

  /// Open the gallery
  public open( idx?: number ): void{
    this.close();
    if (this.items.length > 0 ){
      if ( hasValue( idx ) && ( idx < this.items.length ) ) {
        Fancybox.show( this.items, { startIndex: idx } );
      } else {
        Fancybox.show( this.items );
      }
    }
  }

  /// Close the gallery
  public close(): void{
    Fancybox.close();
  }

  /// Clears the items from the gallery item list
  public clearItems(): void {
    this.items = [];
    this.prepended = 0;
    this.appended = 0;
  }

  /// Adds more items to the gallery item list
  public appendItems( itemHandle: string, bitstreams: Bitstream[] ): void{
    for ( let bitstream of bitstreams ){
      let fileName: string = this.dsoNameService.getName( bitstream );
      let path: string = encodeURI( '/bitstream/handle/' + itemHandle + '/' + fileName );

      if ( FileUtil.isImage( fileName ) ) {
        this.items.push( { src: path } );
      } else
      if ( FileUtil.isVideo( fileName ) ) {
        this.items.push( { src: path, thumb: path + '.jpg' } );
      }

      this.appended++;
    }
  }

  /// Adds more items to the gallery item list
  public prependItems( itemHandle: string, bitstreams: Bitstream[] ): void{
    /// prepend in reverse order, so we keep the original order
    for ( let i = bitstreams.length - 1; i >= 0; i-- ) {
      const bitstream: Bitstream = bitstreams[ i ];
      const fileName: string = this.dsoNameService.getName( bitstream );
      const path: string = encodeURI( '/bitstream/handle/' + itemHandle + '/' + fileName );

      if ( FileUtil.isImage( fileName ) ) {
        this.items.unshift( { src: path } );
      } else
      if ( FileUtil.isVideo( fileName ) ) {
        this.items.unshift( { src: path, thumb: path + '.jpg' } );
      }

      this.prepended++;
    }
  }

  public addItems( bundle: string, itemHandle: string, bitstreams: Bitstream[] ): void {
    if( bundle === 'ORIGINAL' ) {
      this.prependItems( itemHandle, bitstreams );
    } else if ( bundle === 'PRESERVATION' ) {
      this.appendItems( itemHandle, bitstreams );
    }
  }
}
